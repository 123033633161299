<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <BaseModal size="xl" :title="formPhuLucTitle" :show="isPhuLucModalOpen"
               @update:show="value => toggleModal({show:value})">
      <template #default>
        <div>
          <div class="w-100">
            <div class="pdf-wrapper">
              <div>
                <div class="">
                  <div class="pdf-page fs-16">
                    <div id="pdf1" class="pdf-content">
                      <div class="text-center fs-16">
                        <strong class="bold">Mẫu C.II.2</strong><br>
                        <strong>Biểu tổng hợp dự kiến chương trình xúc tiến đầu tư hàng năm</strong><br>
                        <p class="font-italic">(Áp dụng đối với chương trình xúc tiến đầu tư của Bộ/Ủy ban nhân dân cấp
                          tỉnh theo quy định tại Điểm a, Khoản 2, Điều 93 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021)</p>
                        <hr>
                      </div>
                      <div class="row fs-16">
                        <div class="col-md-6">
                          <b class="text-uppercase">{{ item.tenBo_UBNDTinh }}</b>
                        </div>
                        <div class="col-md-6">
                          <p class="font-weight font-italic" style="float: right; display: flex">
                          <span class="mt-2">{{ item.diaDiem }}, ngày {{
                              getDay(item.ngayVanBan)
                            }} tháng {{
                              getMonth(item.ngayVanBan)
                            }} năm {{ getYear(item.ngayVanBan) }}</span>
                          </p>
                        </div>
                      </div>
                      <div class="text-center">
                        <b class="text-uppercase">Biểu tổng hợp dự kiến chương trình xúc tiến đầu tư năm
                          {{ Number(getYear(item.ngayVanBan)) + 1 }}</b>
                        <p class="font-italic">(Kèm theo Văn bản dự kiến Chương trình xúc tiến đầu tư năm
                          {{ Number(getYear(item.ngayVanBan)) + 1 }} của Bộ/UBND cấp tỉnh {{ item.tenBo_UBNDTinh }} tại
                          công văn số {{ item.soVanBan }} ngày {{ getDay(item.ngayVanBan) }} tháng {{
                            getMonth(item.ngayVanBan)
                          }} năm {{ getYear(item.ngayVanBan) }})</p>
                      </div>
                      <div class="float-right" style="display: flex">
                        <p>Đơn vị: triệu VNĐ</p>
                        <button class="btn btn-behance ml-3" @click="addHoatDong">Thêm hoạt động</button>
                      </div>
                      <div class="">
                        <vue-scrolling-table>
                          <template slot="thead" class="bg-secondary">
                            <tr class="bg-secondary">
                              <!--                              <th class="text-center align-middle fs-12 w-50px" rowspan="2">-->
                              <!--                                <i class="c-icon cil-options rotate90"></i>-->
                              <!--                              </th>-->
                              <th class="text-center align-middle fs-12 w-50px" rowspan="3">
                                STT
                              </th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Tên hoạt động <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-200" rowspan="3">Loại hoạt động <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Dự kiến thời gian tổ chức thực hiện
                                (tháng, năm) <span class="text-danger">*</span>
                              </th>
                              <th class="p-1 text-center fs-13 w-200" rowspan="3">Đơn vị chủ trì thực hiện <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" colspan="2">Địa điểm tổ chức <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Mục đích/Nội dung hoạt động <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Ngành/Lĩnh vực kêu gọi đầu tư (ngành
                                ISIC cấp 2) <span class="text-danger">*</span>
                              </th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Địa bàn/Tỉnh/Vùng kêu gọi đầu tư <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Căn cứ triển khai hoạt động <span class="text-danger">*</span></th>
                              <th class="p-1 text-center fs-13 w-120" colspan="4">Đơn vị phối hợp <span class="text-danger">*</span></th>
<!--                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Nguồn kinh phí</th>-->
                              <th class="p-1 text-center fs-13 w-120" colspan="3">Kinh phí</th>
                              <th class="p-1 text-center fs-13 w-50px" rowspan="3">Thao tác</th>
                            </tr>
                            <tr class="bg-secondary">
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Trong nước (địa phương)</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Nước ngoài (quốc gia)</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Tên tổ chức/Cơ quan trong nước</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Tên tổ chức/Cơ quan nước ngoài</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Doanh nghiệp trong nước</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Doanh nghiệp nước ngoài</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Ngân sách địa phương</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Khác (tài trợ)</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Tổng kinh phí</th>
                            </tr>
                            <!--                            <tr class="bg-secondary">-->
                            <!--                              <th class="p-1 text-center fs-13 w-90px">Trong nước (tên doanh nghiệp)</th>-->
                            <!--                              <th class="p-1 text-center fs-13 w-90px">Nước ngoài (tên doanh nghiệp)</th>-->
                            <!--                            </tr>-->
                          </template>
                          <template slot="tbody">
                            <!--                            <tr>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-50px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                              <td class="text-center p-1 fs-13 align-middle w-90px">1</td>-->
                            <!--                            </tr>-->
                            <template v-if="chiTietDeXuatCTXTDT.length">
                              <tr v-for="(it, indx) in chiTietDeXuatCTXTDT" v-bind:key="it.id">
                                <td class="text-center p-1 fs-13 align-middle w-50px">{{ indx + 1 }}</td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control" :rows="1"
                                         placeholder=""
                                         v-model="it.tenHoatDong"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-200">
                                  <select class="form-control" v-model="it.loaiHoatDongId">
                                    <option :value="itemz.value" v-for="itemz in optionsLoaiHoatDong"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <v-date-picker class="" v-model="it.duKienThoiGianToChuc" locale="vi">
                                    <template v-slot="{ inputValue, inputEvents }">
                                      <input
                                        class="form-control"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                      />
                                    </template>
                                  </v-date-picker>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-200">
                                  <select class="form-control" v-model="it.donViChuTriThucHien">
                                    <option :value="itemz.value" v-for="itemz in optionsDonViChuTriThucHien"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
<!--                                  <textarea type="text" class="form-control"-->
<!--                                         v-if="it.donViChuTriThucHienSelect === 'Khác (ghi rõ)'"-->
<!--                                         placeholder="" :rows="1"-->
<!--                                         v-model="it.donViChuTriThucHien"/>-->
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select class="form-control" v-model="it.diaDiem_TrongNuocId">
                                    <option :value="itemz.value" v-for="itemz in optionsTinhThanh"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select class="form-control" v-model="it.diaDiem_NuocNgoaiId">
                                    <option :value="itemz.value" v-for="itemz in optionsQuocGia" v-bind:key="itemz.id">
                                      {{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control" :rows="1"
                                         placeholder=""
                                         v-model="it.mucDich_NoiDungHoatDong"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select class="form-control" v-model="it.nganhLinhVucKeuGoiDauTuId">
                                    <option :value="itemz.value" v-for="itemz in optionsNganhLinhVuc"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select class="form-control" v-model="it.diaBanKeuGoiDauTu">
                                    <option :value="itemz.value" v-for="itemz in optionsDiaBanKeuGoiDauTu"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control" :rows="1"
                                         placeholder=""
                                         v-model="it.canCuTrienKhai"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control" :rows="1"
                                         placeholder=""
                                         v-model="it.toChucCoQuan_TrongNuoc"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control" :rows="1"
                                         placeholder=""
                                         v-model="it.toChucCoQuan_NuocNgoai"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control" :rows="1"
                                         placeholder=""
                                         v-model="it.doanhNghiep_TrongNuoc"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <textarea type="text" class="form-control" :rows="1"
                                         placeholder=""
                                         v-model="it.doanhNghiep_NuocNgoai"/>
                                </td>
<!--                                <td class="text-center p-1 fs-13 align-middle w-120">-->
<!--                                  <select class="form-control" v-model="it.nguonKinhPhi">-->
<!--                                    <option :value="itemz.value" v-for="itemz in optionsNguoiKinhPhi"-->
<!--                                            v-bind:key="itemz.id">{{ itemz.label }}-->
<!--                                    </option>-->
<!--                                  </select>-->
<!--                                </td>-->
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input type="number" class="form-control"
                                         placeholder=""
                                         v-model="it.kinhPhi_NganSachDiaPhuong" @input="it.kinhPhi_NganSachDiaPhuong = Math.abs(it.kinhPhi_NganSachDiaPhuong)"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input type="number" class="form-control"
                                         placeholder=""
                                         v-model="it.kinhPhi_Khac" @input="it.kinhPhi_Khac = Math.abs(it.kinhPhi_Khac)"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  {{ Number(it.kinhPhi_NganSachDiaPhuong) + Number(it.kinhPhi_Khac) | formatNumber }}
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-50px">
                                  <CButton size="sm" color="danger" @click="deleteHoatDong(indx)">
                                    <CIcon name="cil-x"/> Xóa
                                  </CButton>
                                </td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr>
                                <td class="text-center p-1 fs-13 align-middle" colspan="20">Chưa có dữ liệu</td>
                              </tr>
                            </template>
                          </template>
                        </vue-scrolling-table>
                      </div>
                      <div class="col-12 mt-5">
                        <p class="text-danger">Lưu ý: cột dấu sao là cột bắt buộc</p>
                      </div>
                      <div class="float-right mt-3">
                        <button type="button" class="btn btn-secondary mr-2" @click="toggleModal({show: false})">Hủy bỏ</button>
                        <CButton color="ghost" class="mr-2" @click="print">Xuất văn bản</CButton>
                        <button type="button" class="btn btn-primary mr-2" :disabled="invalid || isSaving" @click="handleSubmit(saveItem)">
                          <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Lưu
                          lại
                        </button>
                      </div>
                      <!--                      <div class="form-group row mb-0 mt-3">-->
                      <!--                        <div class="col-12">-->
                      <!--                          <b class="mt-5">Đính kèm file</b>-->
                      <!--                          <FileUpload ref="upload" @onChange="changeFiles" :type="type"/>-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button type="button" class="btn btn-secondary" @click="toggleModal({show: false})">Hủy bỏ</button>
        <button type="button" class="btn btn-primary" :disabled="invalid || isSaving" @click="handleSubmit(saveItem)">
          <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Lưu
          lại
        </button>
      </template>
    </BaseModal>
  </ValidationObserver>
</template>

<script>
import * as types from '@/store/modules/DeXuatCTXTDT_DiaPhuong/actionTypes'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
// import FileUpload from '@/components/file-upload/FileUpload'
import { v4 as uuidv4 } from 'uuid'
import VueScrollingTable from 'vue-scrolling-table'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { quocGiaService } from '@/services/quoc-gia.service'
// import { loaiHoatDongService } from '@/services/loai-hoat-dong.service'
import { enums } from '@/shared/enums'
import { loaiHoatDongService } from '@/services/loai-hoat-dong.service'
import { vungKinhTeService } from '@/services/vung-kinh-te.service'

export default {
  name: 'DeXuatCTXTDTDiaPhuongPhuLucCreateModal',
  props: ['item'],
  components: {
    VueScrollingTable
    // FileUpload
  },
  data () {
    return {
      invalid: true,
      Attachments: [],
      type: 'DeXuatCTXTDT_DiaPhuong',
      // item: {
      //   guid: '',
      //   tenBo_UBNDTinh: '',
      //   soVanBan: '',
      //   nam_ChuongTrinh: '',
      //   diaDiem: '',
      //   ngayVanBan: Date.now(),
      //   danhGiaHoatDongNamTruoc: '',
      //   quanDiemDinhHuongMucTieu: '',
      //   duKienChuongTrinh: '',
      //   toChucThucHien: '',
      //   dinhKem: '',
      //   trangThai: ''
      // },
      chiTietDeXuatCTXTDT: [],
      TrangThaiDeXuatCTXTDT: [
        {
          label: 'TATCA',
          value: 0
        }
      ],
      filterDMLoaiHoatDong: {
        loaiHoatDong: '',
        trangThai: true,
        orderBy: null,
        pageNumber: 1,
        pageSize: 50
      },
      optionsNganhLinhVuc: [],
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsLoaiHoatDong: [],
      optionsNguoiKinhPhi: [],
      optionsDiaBanKeuGoiDauTu: [],
      optionsDonViChuTriThucHien: []
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDTDiaPhuong', {
      formPhuLucTitle: 'formPhuLucTitle',
      isSaving: 'isSaving',
      isPhuLucModalOpen: 'isCreateOrUpdatePhuLucModalOpen',
      phulucItem: 'phulucItem',
      editedItem: 'editedItem',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDTDiaPhuong', {
      toggleModal: types.TOGGLE_CREATE_UPDATE_PHULUC_MODAL,
      createItem: types.CREATE_CTXTDTPHULUCCUABOUBNDTINH,
      updateItem: types.UPDATE_CTXTDTPHULUCCUABOUBNDTINH
    }),
    print () {
      const data = {
        item: this.item,
        chiTietDeXuatCTXTDT: this.chiTietDeXuatCTXTDT
      }
      localStorage.setItem('de_xuat_ct_xtdt_dia_phuong_phu_luc', JSON.stringify(data))
      const routeData = this.$router.resolve({ path: '/de-xuat-ct-xtdt-dia-phuong/bieu-in-phu-luc' })
      window.open(routeData.href, '_blank')
    },
    async saveItem () {
      // const validate = await this.$refs.form.validate()
      // console.log(validate)
      // if (validate) {
      for (const i in this.chiTietDeXuatCTXTDT) {
        this.chiTietDeXuatCTXTDT[i].loaiHoatDongId = Number(this.chiTietDeXuatCTXTDT[i].loaiHoatDongId)
        this.chiTietDeXuatCTXTDT[i].diaDiem_TrongNuocId = Number(this.chiTietDeXuatCTXTDT[i].diaDiem_TrongNuocId)
        this.chiTietDeXuatCTXTDT[i].diaDiem_NuocNgoaiId = Number(this.chiTietDeXuatCTXTDT[i].diaDiem_NuocNgoaiId)
        this.chiTietDeXuatCTXTDT[i].kinhPhi_NganSachDiaPhuong = Number(this.chiTietDeXuatCTXTDT[i].kinhPhi_NganSachDiaPhuong)
        this.chiTietDeXuatCTXTDT[i].kinhPhi_Khac = Number(this.chiTietDeXuatCTXTDT[i].kinhPhi_Khac)
        this.chiTietDeXuatCTXTDT[i].nganhLinhVucKeuGoiDauTuId = Number(this.chiTietDeXuatCTXTDT[i].nganhLinhVucKeuGoiDauTuId)
        // if (this.chiTietDeXuatCTXTDT[i].donViChuTriThucHienSelect !== 'Khác (ghi rõ)') {
        //   this.chiTietDeXuatCTXTDT[i].donViChuTriThucHien = this.chiTietDeXuatCTXTDT[i].donViChuTriThucHienSelect
        // }
      }
      if (this.editedItem.deXuatCTXTDTGuid) {
        this.editedItem.chiTietDeXuatCTXTDT = this.chiTietDeXuatCTXTDT
        await this.updateItem(this.editedItem)
      } else {
        const data = {
          deXuatCTXTDTGuid: this.item.guid,
          chiTietDeXuatCTXTDT: this.chiTietDeXuatCTXTDT
        }
        await this.createItem(data)
      }
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.toggleModal({ show: false })
        // this.$emit('refresh-list', true)
      }
      // }
    },
    changeFiles (data) {
      this.Attachments = data.ListFileAttachAdd
    },
    addHoatDong () {
      const item = {
        deXuatGuid: this.item.guid,
        guid: uuidv4(),
        tenHoatDong: '',
        loaiHoatDongId: null,
        duKienThoiGianToChuc: '',
        // donViChuTriThucHienSelect: '',
        donViChuTriThucHien: '',
        diaDiem_TrongNuocId: null,
        diaDiem_NuocNgoaiId: null,
        mucDich_NoiDungHoatDong: '',
        nganhLinhVucKeuGoiDauTuId: null,
        diaBanKeuGoiDauTu: null,
        canCuTrienKhai: '',
        toChucCoQuan_TrongNuoc: '',
        toChucCoQuan_NuocNgoai: '',
        doanhNghiep_TrongNuoc: '',
        doanhNghiep_NuocNgoai: '',
        nguonKinhPhi: 0,
        kinhPhi_NganSachDiaPhuong: 0,
        kinhPhi_Khac: 0,
        soLanHieuChinh: 0,
        trangThai: 0
      }
      this.chiTietDeXuatCTXTDT.push(item)
    },
    deleteHoatDong (index) {
      if (confirm('Bạn có chắc chắn muốn xóa không?')) {
        this.chiTietDeXuatCTXTDT.splice(index, 1)
      }
    },
    getDay: (time, format = 'DD/MM/YYYY') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'DD/MM/YYYY') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'DD/MM/YYYY') => time ? moment(time, format).format('YYYY') : ''
  },
  async mounted () {
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsLoaiHoatDong = await loaiHoatDongService.getAll()
    // this.optionsLoaiHoatDong = enums.loaiHoatDongDeXuatCTXTDT
    this.optionsNguoiKinhPhi = enums.nguonKinhPhi
    // this.optionsDiaBanKeuGoiDauTu = enums.diaBanKeuGoiDauTuDeXuatCTXTDT
    this.optionsDiaBanKeuGoiDauTu = await vungKinhTeService.getAll()
    this.optionsDonViChuTriThucHien = enums.donViChuTriDeXuatCTXTDT
  },
  watch: {
    item: function (val) {
      console.log(val)
    }
  }
}
</script>
